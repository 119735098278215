<template>
  <div class="min-width-110">
    <b-media
      class="align-item-center event-Tag dynamic-event"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.READ,
          subject: constants.PERMISSIONS_MODEL.LABELS
        })
      "
    >
      <div v-for="(tag, index) in tags" :key="index">
        <span v-if="typeof tag === 'string' ? tag : tag.name || ''">
          <div
            style="padding-right: 3px"
            class="d-flex align-items-center"
            v-if="index < maxTagShow"
          >
            <b-badge variant="primary" style="line-height: normal" pill>
              {{ formattedDescriptions(tag.name || tag, showTextInBadge) }}
            </b-badge>
          </div>
        </span>
      </div>

      <b-badge
        variant="primary"
        pill
        style="line-height: normal"
        :id="event.id + `id`"
        tabindex="0"
        class="cursor-pointer"
        @click="onEditableTags"
        v-b-tooltip.hover.top="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.ADD,
            subject: constants.PERMISSIONS_MODEL.LABELS
          })
            ? $t('addLabels')
            : $t('ViewLabels')
        "
        v-if="
          (tags && tags.length > maxTagShow) ||
          (tags.length <= maxTagShow &&
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.LABELS
            })) ||
          (tags.length == 0 &&
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.LABELS
            }))
        "
        >{{
          tags.length > maxTagShow
            ? Number(tags.length) - maxTagShow + "+"
            : "+"
        }}
      </b-badge>
      <b-popover
        :target="event.id + `id`"
        triggers="click"
        placement="lefttop"
        custom-class="pop-over-primary"
        :show.sync="showPopover"
      >
        <div class="d-flex align-items-start">
          <div
            class="d-flex-tool label-batch"
            v-if="!isEditable"
            :style="
              tags.length ? `${'width:' + tags.length * 30}` : 'width:300px'
            "
          >
            <div v-for="(tag, index) in tags" :key="index">
              <span v-if="typeof tag === 'string' ? tag : tag.name || ''">
                <div style="padding: 3px" class="d-flex align-items-center">
                  <b-badge pill variant="primary">
                    {{ tag.name }}
                  </b-badge>
                </div></span
              >
            </div>
          </div>
          <div class="w-100" v-if="isEditable">
            <TagEntity
              v-if="isEditable"
              v-model="tags"
              :isList="isList"
              :event="event"
              :entityType="entityType"
              :isEditableTags="isEditableTags"
              :listApi="listApi"
            ></TagEntity>
          </div>
          <div
            class="badge-icon"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.LABELS
              }) ||
              checkAbility({
                action: constants.PERMISSIONS_ACTION.UPDATE,
                subject: constants.PERMISSIONS_MODEL.LABELS
              })
            "
          >
            <feather-icon
              v-if="!showAllTag && !isEditable"
              v-b-tooltip.hover.left="$t('tooTip.update')"
              icon="EditIcon"
              class="action-icon cursor-pointer right-0"
              size="18"
              @click="isEditableTags"
            />
          </div>
          <feather-icon
            icon="XCircleIcon"
            class="action-icon cursor-pointer right-0 close-icon-circle"
            size="18"
            @click="closePopOver"
          />
        </div>
      </b-popover>
    </b-media>
  </div>
</template>
<script>
import { BMedia, BBadge, BPopover, VBTooltip } from "bootstrap-vue";
import TagEntity from "@/layouts/components/TagEntity.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BMedia,
    BBadge,
    BPopover,
    TagEntity,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip
  },

  props: {
    tagsData: {
      type: Array,
      default: () => []
    },
    maxTagShow: {
      type: Number,
      default: () => 5
    },
    event: {
      type: Object
    },
    showTextInBadge: {
      type: Number,
      default: () => 7
    },
    isList: {
      type: Boolean,
      default: () => false
    },
    entityType: {
      type: String,
      default: () => ""
    },
    showAllTag: {
      type: Boolean,
      default: () => false
    },
    listApi: {
      type: Function
    }
  },
  data() {
    return {
      isEditable: false,
      showPopover: false,
      tags: []
    };
  },
  watch: {
    tagsData(val) {
      this.tags = val;
    }
  },
  mounted() {
    this.tags = this.tagsData;
  },
  methods: {
    isEditableTags() {
      this.isEditable = !this.isEditable;
    },
    onEditableTags() {
      this.isEditable = true;
    },
    formattedDescriptions(description, len) {
      return description && description.length > len
        ? `${description.slice(0, len).trim()}...`
        : description;
    },
    closePopOver() {
      this.isEditable = !this.isEditable;
      this.showPopover = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.dynamic-event.event-Tag {
  justify-content: flex-start !important;
}
.event-Tag .media-body {
  display: flex;
  // flex-wrap: wrap;
}
.d-flex-tool {
  display: flex !important;
  flex-wrap: wrap;
}
.badge-icon {
  text-align: right;
  margin-bottom: 8px;
  margin-top: 8px;
}
.close-icon-circle {
  position: absolute;
  top: -10px;
  right: -17px;
  background: #fff;
  border-radius: 10px;
}
.min-width-110 {
  min-width: 110px;
  .media {
    padding-top: 0px;
  }
}
</style>
