<template>
  <div>
    <b-table
      class="position-relative min-600-table header-left-align"
      :class="
        component_key === 'allocation'
          ? 'scroll-h-table-al'
          : 'scroll-h-table-create'
      "
      responsive
      show-empty
      align-v="end"
      :items="myHistoryData"
      :fields="tableColumns"
      :empty-text="$t('NoMatchingRecordsFound')"
    >
      <template #cell(account)="row">
        <h6 class="mb-0 td-width">
          {{ `${row.item.account ? `${row.item.account}` : " "}` }}
        </h6>
      </template>
      <template #cell(start_date)="row">
        <h6 class="mb-0 td-width">
          {{
            `${
              row.item.start_date && row.item.start_date.includes("Z")
                ? `${DateTimeConvert(row.item.start_date)}`
                : row.item.start_date
                ? row.item.start_date
                : ""
            }`
          }}
        </h6>
      </template>
      <template #cell(end_date)="row">
        <h6 class="mb-0 td-width">
          {{
            `${
              row.item.end_date ? `${DateTimeConvert(row.item.end_date)}` : " "
            }`
          }}
        </h6>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        sm="12"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-if="isPaginationVisible"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import constants from "@/utils/constants";
import { BTable, BRow, BCol, BPagination } from "bootstrap-vue";

export default {
  name: "History",
  components: {
    BTable,
    BRow,
    BCol,
    BPagination
    // AllocationPopUp: () => import("../../views/Unit/AllocationPopUp.vue")
  },
  props: [
    "myHistoryData",
    "currentPage",
    "totalRows",
    "perPage",
    "callMyApiPart",
    "isPaginationVisible",
    "isUnitActionButton",
    "selectedAllocatedToId",
    "showPopUp",
    "component_key"
  ],
  data() {
    return {
      items: [],
      tableColumns: [
        { key: "account", label: this.$t("historyTableColumns.account") },
        {
          key: "start_date",
          label: this.$t("historyTableColumns.startDate")
        },
        {
          key: "end_date",
          label: this.$t("historyTableColumns.endDate")
        }
      ],
      allocationData: null,
      c1: null,
      status: false
    };
  },
  mounted() {
    // this.tableColumns = ["account", "start_date", "end_date"];
    // this.callMyApiPart({
    //   perPage: this.perPage,
    //   currentPage: this.currentPage
    // });
  },
  watch: {
    perPage() {
      if (!this.perPage) {
        this.perPage = 10;
      }
      // this.callMyApiPart({
      //   perPage: this.perPage,
      //   currentPage: this.currentPage
      // });
    },
    currentPage() {
      this.callMyApiPart({
        perPage: this.perPage,
        currentPage: this.currentPage
      });
    },
    selectedAllocatedToId(val) {
      if (val) {
        setTimeout(() => {
          this.showPopUp(val);
        }, 1000);
      }
    }
  },
  methods: {
    async changeInput(allocationData) {
      this.allocationData = allocationData;
    },
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    }
  }
};
</script>
<style lang="scss" scoped>
.td-width {
  white-space: nowrap;
}
.table-responsive {
  height: auto;
}
.scroll-h-table-create {
  height: calc(100vh - 397px);
}
.scroll-h-table-al {
  height: 200px !important;
}
</style>
