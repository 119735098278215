import APIService from "./api";

/** This class will include all services defined in device-service */
export default class DeviceService extends APIService {
  service = "device-service";
  async deviceList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deviceList" },
      data,
      options
    );
  }
  async parserModelData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "parserModelData" },
      data,
      options
    );
  }
  async deviceAllocationHistory(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deviceAllocationHistory" },
      data,
      options
    );
  }
  async getOneDevice(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getOneDevice" },
      data,
      options
    );
  }
  async isDeviceEditable(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "isDeviceEditable" },
      data,
      options
    );
  }
  async createDevice(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createDevice" },
      data,
      options
    );
  }
  async updateDevice(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateDevice" },
      data,
      options
    );
  }
  async deleteDevice(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteDevice" },
      data,
      options
    );
  }
  async getAllModel(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllModel" },
      data,
      options
    );
  }
  async getAllManufacturer(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllManufacturer" },
      data,
      options
    );
  }
  async getAllSensors(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllSensors" },
      data,
      options
    );
  }
  async getProtocolParams(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getProtocolParams" },
      data,
      options
    );
  }
  async reallocateDevice(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "reallocateDevice" },
      data,
      options
    );
  }
  async updateDeviceParametersReOrder(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateDeviceParametersReOrder" },
      data,
      options
    );
  }
  async updateDeviceParamaters(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateDeviceParamaters" },
      data,
      options
    );
  }
  async getAllCommands(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getModelCommands" },
      data,
      options
    );
  }
  async getDeviceSensors(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getDeviceSensors" },
      data,
      options
    );
  }
  async saveFilters(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "saveFilters" },
      data,
      options
    );
  }
  async getFilters(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getFilters" },
      data,
      options
    );
  }
  async upsertSim(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "upsertSim" },
      data,
      options
    );
  }
  async getSims(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getSims" },
      data,
      options
    );
  }
  async getSim(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getSim" },
      data,
      options
    );
  }
  async getSimsDashboard(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getSimsDashboard" },
      data,
      options
    );
  }
  async updateSim(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateSim" },
      data,
      options
    );
  }
  async createSim(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createSim" },
      data,
      options
    );
  }
  async getSimDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getSim" },
      data,
      options
    );
  }

  async createRechargeHistory(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createRechargeHistory" },
      data,
      options
    );
  }
  async updateRechargeHistory(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateRechargeHistory" },
      data,
      options
    );
  }
  async getRecharges(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRecharges" },
      data,
      options
    );
  }
  async getRecharge(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRecharge" },
      data,
      options
    );
  }
  async reallocateSim(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "reallocateSim" },
      data,
      options
    );
  }
  async removeSim(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeSim" },
      data,
      options
    );
  }
  async performSimOperations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "performSimOperations" },
      data,
      options
    );
  }
  async removeRecharge(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeRecharge" },
      data,
      options
    );
  }
  async bulkCreateSims(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "bulkCreateSims" },
      data,
      options
    );
  }
  async getSimsNameList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getSimsNameList" },
      data,
      options
    );
  }
}
